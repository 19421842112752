<router-outlet></router-outlet>

<app-notification-messages></app-notification-messages>
<app-confirmation-modal></app-confirmation-modal>
<app-share-modal></app-share-modal>
<app-lightbox></app-lightbox>
<app-progress-messages></app-progress-messages>

<!-- need for onboarding -->
<div id="curtain" class="absolute top-0 left-0 w-full h-screen bg-opacity-10 bg-black z-[100]"></div>

@if (showCookieBanner) {
  <div role="alert" class="alert text-sm flex justify-between fixed bottom-0 left-0 w-full z-50 px-5 py-4 bg-secondary text-black
dark:border-success dark:border-opacity-20 items-center bg-opacity-90">
    <div>
      To provide you with a great user experience, we require cookies. By continuing to browse our site, you agree to our
      <a href="https://sendphoto.io/cookie-policy.html" class="underline">Cookie Policy</a>,
      <a href="https://sendphoto.io/privacy-policy.html" class="underline">Privacy Policy</a>
      and <a href="https://sendphoto.io/terms-and-conditions.html" class="underline">Terms of Service</a>.
    </div>
    <button (click)="dismissCookieBanner()" class="transition w-40 duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 font-medium cursor-pointer focus:ring-4
  focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50
   [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-secondary border-opacity-5
  text-white dark:border-success dark:border-opacity-20 [&:hover:not(:disabled)]:bg-opacity-90">Got It
    </button>
  </div>
}
